body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.navbar {
  box-shadow: 0 1px 0 0 rgba(142,81,199,0.05);
}

.navbar-icon {
  margin-right: 8px;
}

.navbar-item {
  font-family: 'Lato'
}

.is-theme-colored.is-loading::after {
  border: 2px solid #ea1044;
  border-right-color: transparent;
  border-top-color: transparent;
}

/* @media screen and (min-width: 1088px) {
  .navbar-item img {
    max-height: 2.8rem !important;
  }
} */

.less-padding .card-content {
  padding: 0.5rem 0.5rem;
}

.card-content > .title {
  margin-bottom: 1rem !important;
}

.card-mid {
  margin-bottom: 0.75rem !important;
  overflow: hidden;
  vertical-align: bottom;
}

.card-mid > p, .card-mid > div {
  display: inline-block
}

.article {
  cursor: pointer;
  margin-bottom: 2rem;
  transition: all .1s ease-in-out;
}

.article:hover {
  transform: scale(1.005);
  box-shadow: 0 0 2px 2px rgba(30, 30, 30, 0.1), 0 0 0 1px rgba(30, 30, 30, 0.1)
}

.is-size-6-half {
  font-size: 0.9rem !important;
}

label.label {
  font-family: 'Lato'
}

p.help {
  font-family: 'Lato'
}

.btn-hour {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  cursor: pointer !important;
  background-color: #fdfdfd;
}

.btn-hour.btn-loaded {
  color: #ea1044;
  border-color: #ea1044;
}

.btn-hour.btn-loaded:hover {
  border-color: #c00000
}

.btn-hour.btn-loaded:active {
  border-color: #900000
}

.btn-hour.btn-active {
  background-color: #ea1044;
  border-color: #ea1044;
  color: white
}

.is-theme-colored {
  border-color: #ea1044;
  color: #ea1044;
  font-family: 'Lato';
}

.is-theme-colored.is-active {
  background-color: #ea1044;
  border-color: #ea1044;
  color: white
}

.is-theme-colored:hover {
  background-color: #ea1044;
  border-color: #ea1044;
  color: white
}

.height-container {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 1088px) {
  .height-container {
    min-height: 25rem;
  }
}

.modal-bottom {
  width: 100%;
  max-height: 80%;
  bottom: 0;
  position: fixed;
  margin: 0;
}

.modal-heading {
  font-family: 'Lato';
  font-weight: 900;
}

.box.better-border {
  border-radius: 16px 16px 0 0;
}


.panel-full {
  width: 100%;
}

.nav-choice a {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0.2em 1.25em;
  margin: 0.05em 0;
  color: #363636;
  border-radius: 16px;
}

.nav-choice a[disabled] {
  color: #B8B8B8;
}

.nav-choice a:hover:not([disabled]) {
  background-color: whitesmoke;
}

.nav-choice a.is-active {
  background-color: whitesmoke;
}

.nav-choice-mobile a {
  padding: 0.2em 1em;
  margin: 0.25em 0.25em;
  color: #363636;
  border-radius: 16px;
  background-color: whitesmoke;
}

.nav-choice-mobile a[disabled] {
  color: #B8B8B8;
}

.nav-choice-mobile a.is-active {
  background-color: #fff588;
}
.columns.col-pad {
  height: 1.5rem;
}

.columns.col-pad-x2 {
  height: 3rem;
}

.is-expanded > div {
  width: 100%
}

.search-box {
  background-color: #fafafa;
}

.search-box > label {
  color: #646464;
}

.search-input, .search-input:hover {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  border-bottom: solid 1px #646464;
  background-color: #fafafa;
}

.search-input:focus {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  border-bottom: solid 1px #646464;
}

.search-btn {
  margin-left: 1px;
  border-color: #646464;
  border-radius: 0;
  border-width: 0 0 1px 0;
  background-color: #fafafa;
}

.search-btn:hover {
  margin-left: 1px;
  border-color: #646464;
  border-radius: 0;
  background-color: #fafafa;
}

.search-btn > i {
  color: #ff2b56;
}

.box-card > label > span {
  color: gray;
  font-weight: 100;
}

.box-card > .level {
  padding-bottom: 7px;
  border-bottom: solid 1px #f0f0f0;
}

.box-card {
  margin-top: 5px;
}

.column-card > label > span {
  font-size: small;
}

.column-card > label {
  margin-bottom: 1px !important;
}

.column-card > p {
  font-size: larger;
}

.table-record > tbody > tr > td {
  text-align: center;
  vertical-align: middle;
}

.table-record > thead > tr > th {
  text-align: center;
  vertical-align: middle;
}

.table-record > tbody > tr > th {
  vertical-align: middle;
}

.table-record {
  font-size: small;
}


.span-full {
  background-color: #4a4a4a;
  border-radius: 25px;
  color: #fff;
  display: block;
  /* font-weight: 400; */
  justify-content: space-between;
  line-height: 1.25;
  padding: .4em .5em;
  position: relative;
  margin-bottom: .5rem;
}

.span-full > p {
  padding: 3px 5px;
  box-sizing: border-box;
}

.level-margin {
  margin: 2px 10px;
}

.run-through {
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: 20px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  transition: 0.1s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: #23D160;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #23D160;
  cursor: pointer;
}

.nav-choice-sate a {
  padding: 0.2em 1em;
  margin: 0.25em 0.25em;
  color: #363636;
  border-radius: 16px;
  background-color: whitesmoke;
}

.nav-choice-sate a.is-active {
  color: white;
  background-color: #23D160;
}

.notification {
  padding: .6rem 1.5rem .6rem 2.5rem
}

.bd-main {
  padding: 1.5rem 2rem;
  min-height: 32rem;
  margin-bottom: 3rem;
}

.content {
  line-height: 1.8 !important;
}

