.span-full {
  background-color: #4a4a4a;
  border-radius: 25px;
  color: #fff;
  display: block;
  /* font-weight: 400; */
  justify-content: space-between;
  line-height: 1.25;
  padding: .4em .5em;
  position: relative;
  margin-bottom: .5rem;
}

.span-full > p {
  padding: 3px 5px;
  box-sizing: border-box;
}

.level-margin {
  margin: 2px 10px;
}

.run-through {
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: 20px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: #23D160;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #23D160;
  cursor: pointer;
}

.nav-choice-sate a {
  padding: 0.2em 1em;
  margin: 0.25em 0.25em;
  color: #363636;
  border-radius: 16px;
  background-color: whitesmoke;
}

.nav-choice-sate a.is-active {
  color: white;
  background-color: #23D160;
}
