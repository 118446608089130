.navbar {
  box-shadow: 0 1px 0 0 rgba(142,81,199,0.05);
}

.navbar-icon {
  margin-right: 8px;
}

.navbar-item {
  font-family: 'Lato'
}

.is-theme-colored.is-loading::after {
  border: 2px solid #ea1044;
  border-right-color: transparent;
  border-top-color: transparent;
}

/* @media screen and (min-width: 1088px) {
  .navbar-item img {
    max-height: 2.8rem !important;
  }
} */
